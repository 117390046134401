import React, { useCallback, Fragment, useRef, useEffect } from 'react';
import cl from 'classnames';

import { Translate } from '../Translate';

export interface ThreeStatesCheckboxProps {
  checkboxClassName?: string;
  checkboxWrapperClassName?: string;
  checked?: boolean;
  disabled?: boolean;
  error?: string | null;
  errorClassName?: string;
  i18nLabel?: string;
  id?: string;
  indeterminate?: boolean;
  label?: string;
  labelClassName?: string;
  name?: string;
  onChange?: (value: boolean) => void;
}

function ThreeStatesCheckbox({
  checkboxClassName,
  checkboxWrapperClassName,
  checked = false,
  disabled,
  error,
  errorClassName,
  i18nLabel,
  id,
  indeterminate = false,
  label,
  labelClassName,
  name,
  onChange
}: ThreeStatesCheckboxProps) {
  const checkboxRef = useRef<HTMLInputElement>();

  useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate;
  }, [checkboxRef, indeterminate]);

  const handleCheck = useCallback(
    () => onChange && onChange(!checked),
    [checked, onChange]
  );

  const checkboxView = (
    <input
      checked={checked}
      className={cl(
        checkboxClassName ||
          'basic-checkbox indeterminate:bg-gray-400 hover:indeterminate:bg-gray-500 dark:indeterminate:bg-gray-700 dark:hover:indeterminate:bg-gray-800'
      )}
      disabled={disabled}
      id={id}
      name={name}
      onChange={disabled ? undefined : handleCheck}
      ref={checkboxRef}
      type="checkbox"
    />
  );

  if (checkboxWrapperClassName) {
    return (
      <Fragment>
        <div className={checkboxWrapperClassName}>
          {checkboxView}

          {i18nLabel || label ? (
            <label
              className={cl(
                labelClassName ||
                  'ml-2 block text-sm text-gray-900 dark:text-gray-100'
              )}
              onClick={disabled ? undefined : handleCheck}
            >
              {i18nLabel ? <Translate id={i18nLabel} /> : label}
            </label>
          ) : null}
        </div>

        {error && (
          <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </p>
        )}
      </Fragment>
    );
  }

  return checkboxView;
}

export default ThreeStatesCheckbox;
