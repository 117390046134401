import * as yup from 'yup';

import {
  TaskAcceptResultsFields,
  TaskNanoID,
  TaskState,
  TaskStates,
  TaskUUID
} from '../../../../../tasksTypes';
import { ReactHookFormMode } from '../../../../../../common/hooks/base/useReactHookForm/useReactHookForm.types';
import { AcceptResultsInTaskFormData } from '../../AcceptResultsInTaskForm.types';

import {
  ACCEPT_RESULTS_IN_TASK_QUERY,
  AcceptResultsInTaskQueryResponse
} from '../../../../../queries/acceptResultsInTask.query';
import {
  ACCEPT_RESULTS_IN_DRAFT_TASK_QUERY,
  AcceptResultsInDraftTaskQueryResponse
} from '../../../../../queries/acceptResultsInDraftTask.query';

import { useAcceptResultsInTask } from '../../../../../hooks/useAcceptResultsInTask';
import { useAcceptResultsInDraftTask } from '../../../../../hooks/useAcceptResultsInDraftTask';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { TaskCache } from '../../../../../TaskCache';
import { TaskProcessingJobCache } from '../../../../../../taskProcessingJobs/TaskProcessingJobCache';
import { TasksPermissions } from '../../../../../tasksConstants';

import { formsErrors } from '../../../../../../../locales/keys';

const acceptResultsInTaskFormData: AcceptResultsInTaskFormData = {
  mark: 5
};

interface AcceptResultsInTaskFormOptions {
  taskUuid: TaskUUID;
  taskNanoId: TaskNanoID;
  withoutAccept?: boolean;
  withoutClose?: boolean;
  state?: TaskState;
}

const acceptTaskValidationSchema = yup.object({
  [TaskAcceptResultsFields.MARK]: yup
    .number()
    .min(1, formsErrors.required)
    .nullable(true)
    .required(formsErrors.required)
});

function useAcceptResultsInTaskForm({
  taskUuid,
  taskNanoId,
  withoutAccept,
  withoutClose,
  state
}: AcceptResultsInTaskFormOptions) {
  const currentUser = useCurrentUser();

  const isDraftTask = state === TaskStates.DRAFT;

  const {
    handleSubmitReactHookForm,
    register,
    errors,
    control,
    resetForm,
    watch,
    isValid
  } = useReactHookForm<AcceptResultsInTaskFormData>({
    defaultValues: acceptResultsInTaskFormData,
    schema: isDraftTask ? undefined : acceptTaskValidationSchema,
    mode: ReactHookFormMode.ON_CHANGE
  });

  const {
    acceptResultsInTask,
    acceptResultsInTaskLoading,
    acceptResultsInTaskErrorMessage,
    acceptResultsInTaskReset
  } = useAcceptResultsInTask<AcceptResultsInTaskQueryResponse>({
    query: ACCEPT_RESULTS_IN_TASK_QUERY,
    cacheKeys: [
      TaskCache.indexCacheKey(),
      TaskCache.showSidebarCacheKey(),
      TaskCache.showDashboardCacheKey(),
      TaskCache.showHeaderActionsCacheKey(),
      TaskCache.showQueryKey(taskNanoId),
      TaskCache.messagesCacheKey(taskNanoId),
      TaskCache.itemsResultsCacheKey(),
      TaskCache.withoutSourceFiles(),
      TaskCache.dashboardWithoutSourceFiles(),
      TaskCache.userResultsCacheKey(),
      TaskCache.userActiveTasksCacheKey(),
      TaskCache.activeTaskCacheKey(taskNanoId),
      TaskCache.compactIndexCacheKey(),
      TaskCache.compactTimelineCacheKey(),
      TaskCache.userTasksCacheKey(currentUser?.nanoId),
      TaskProcessingJobCache.taskTaskProcessingJobCacheKey(taskNanoId)
    ]
  });

  const {
    acceptResultsInDraftTask,
    acceptResultsInDraftTaskLoading,
    acceptResultsInDraftTaskErrorMessage,
    acceptResultsInDraftTaskReset
  } = useAcceptResultsInDraftTask<AcceptResultsInDraftTaskQueryResponse>({
    query: ACCEPT_RESULTS_IN_DRAFT_TASK_QUERY,
    cacheKeys: [
      TaskCache.indexCacheKey(),
      TaskCache.showSidebarCacheKey(),
      TaskCache.showDashboardCacheKey(),
      TaskCache.showHeaderActionsCacheKey(),
      TaskCache.showQueryKey(taskNanoId),
      TaskCache.messagesCacheKey(taskNanoId),
      TaskCache.itemsResultsCacheKey(),
      TaskCache.withoutSourceFiles(),
      TaskCache.dashboardWithoutSourceFiles(),
      TaskCache.userResultsCacheKey(),
      TaskCache.userActiveTasksCacheKey(),
      TaskCache.activeTaskCacheKey(taskNanoId),
      TaskCache.compactIndexCacheKey(),
      TaskCache.compactTimelineCacheKey(),
      TaskCache.userTasksCacheKey(currentUser?.nanoId),
      TaskProcessingJobCache.taskTaskProcessingJobCacheKey(taskNanoId)
    ]
  });

  return {
    control,
    acceptResultsInTaskLoading: isDraftTask
      ? acceptResultsInDraftTaskLoading
      : acceptResultsInTaskLoading,
    acceptResultsInTaskErrorMessage: isDraftTask
      ? acceptResultsInDraftTaskErrorMessage
      : acceptResultsInTaskErrorMessage,
    acceptResultsInTaskReset: isDraftTask
      ? acceptResultsInDraftTaskReset
      : acceptResultsInTaskReset,
    isValid,
    resetForm,
    handleAcceptResultsInTask: handleSubmitReactHookForm({
      onSubmit: async (data: AcceptResultsInTaskFormData) =>
        isDraftTask
          ? acceptResultsInDraftTask({
              uuid: taskUuid
            })
          : acceptResultsInTask({
              withoutAccept,
              withoutClose,
              uuid: taskUuid,
              async: currentUser.hasPermissions(
                TasksPermissions.READ_ACCEPT_RESULTS_IN_TASK_ASYNC_BUTTON
              ),
              ...data
            }),
      dirtyFieldsOnly: false
    }),
    registerSupportMark: register(TaskAcceptResultsFields.MARK),
    validationErrors: {
      markValidationError: errors?.mark?.message
    },
    watchMark: watch(TaskAcceptResultsFields.MARK)
  };
}

export default useAcceptResultsInTaskForm;
